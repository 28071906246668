import Vue from 'vue'
import { Line, Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins


Chart.defaults.scale.gridLines.drawOnChartArea = false;
// Chart.defaults.global.tooltips = 

Vue.component("graph-line", {
  extends: Line,
  mixins: [reactiveProp],
  props: ['options', 'width', 'height'],
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options)
    this.$refs.canvas.parentNode.style.height = this.height
    this.$refs.canvas.parentNode.style.width = this.width
    this.$emit('passGraph', this)
  }
});

Vue.component("graph-bar", {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['options', 'width', 'height'],
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options)
    this.$refs.canvas.parentNode.style.height = this.height
    this.$refs.canvas.parentNode.style.width = this.width
    this.$emit('passGraph', this)
  }
});
