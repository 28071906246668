import _ from 'lodash'
import Vue from 'vue'

export const state = () => ({
  creativeData: {}
})

export const mutations = {
  setCreative(state, payload){
    Vue.set(state, 'creativeData', payload.creativeData)
  }
}
