<template lang="html">
  <transition name="fade">
    <div class="welcome" v-show="showModal" v-bind:class="classObject">
      <div class="welcome__content">
        <div class="welcome__title">
          Welcome to
          <div>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="320" height="100" viewBox="0 0 320 100">
              <title>mdtn-studio</title>
              <path d="M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0ZM69.424,74.261H60.487V44.055l-7.9,14.327H47.267L39.144,43.833V74.261H30.576V25.739h7.9l11.6,21.123L61.521,25.739h7.9Z" fill="#2196f3"/>
              <path d="M185.648,35.139h6.194V25.6h7.047v9.538h6.264v5.909h-6.264V69.665h-7.047V41.048h-6.194Z" fill="#2196f3"/>
              <path d="M210.567,34.854h7.118v22.78c0,3.773,2.064,6.549,6.051,6.549,4.2,0,6.762-3.132,6.762-7.261V34.854h7.119V69.665h-5.908L231,66.034a10.848,10.848,0,0,1-9.041,4.343c-7.474,0-11.389-5.054-11.389-12.459Z" fill="#2196f3"/>
              <path d="M265.044,66.034a10.486,10.486,0,0,1-8.9,4.343c-7.759,0-11.958-5.34-11.958-13.242V47.384c0-7.9,4.2-13.242,11.958-13.242a10.73,10.73,0,0,1,8.4,3.7V21.471h7.047V69.665h-5.837Zm-.5-9.112v-9.4c0-4.057-2.634-7.189-6.834-7.189s-6.407,2.99-6.407,6.905V57.278c0,3.915,2.206,6.905,6.407,6.905S264.547,61.051,264.547,56.922Z" fill="#2196f3"/>
              <path d="M285.81,34.854V69.665h-7.118V34.854Z" fill="#2196f3"/>
              <path d="M320,56.566c0,8.471-5.624,13.811-13.81,13.811s-13.81-5.34-13.81-13.811V47.953c0-8.471,5.624-13.811,13.81-13.811S320,39.482,320,47.953Zm-20.5.569c0,4.13,2.42,7.191,6.691,7.191s6.692-3.061,6.692-7.191V47.384c0-4.13-2.421-7.191-6.692-7.191s-6.691,3.061-6.691,7.191Z" fill="#2196f3"/>
              <path d="M172.1,49.234l-5.41-1.637c-2.847-.854-3.773-2.136-3.773-3.915,0-2.777,2.776-4.2,5.7-4.2,3.915,0,6.122,1.992,6.122,4.7v.666l6.62.254v-.706c0-6.407-5.125-10.323-12.885-10.323-7.332,0-12.1,4.77-12.1,10.18,0,5.481,3.844,8.258,8.186,9.61l6.407,1.993c2.847.855,4.058,2.064,4.058,4.342,0,2.919-2.136,4.77-6.336,4.77-4.129,0-6.62-1.993-6.62-4.982V58.959l-6.621-.255V59.77c0,6.549,5.553,10.678,13.383,10.678,7.973,0,12.885-4.485,12.885-10.963C181.713,53.15,177.3,50.872,172.1,49.234Z" fill="#2196f3"/>
              <path d="M129.474,74.488h-2.227V25.512h2.227Z" fill="#2196f3"/>
            </svg>
          </div>
        </div>
        <div class="welcome__perks">
          <ul>
            <li>
              <svg viewBox="0 0 13 13">
                <path
                  stroke="#ffffff"
                  stroke-width="2.057"
                  d="M3 6.888L6.16 10.2 11 3"
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              Easy step by step workflow
            </li>
            <li>
              <svg viewBox="0 0 13 13">
                <path
                  stroke="#ffffff"
                  stroke-width="2.057"
                  d="M3 6.888L6.16 10.2 11 3"
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              No registration
            </li>
            <li>
              <svg viewBox="0 0 13 13">
                <path
                  stroke="#ffffff"
                  stroke-width="2.057"
                  d="M3 6.888L6.16 10.2 11 3"
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              Pay as you go
            </li>
          </ul>
        </div>
        <div class="welcome__terms">
          <p>
            To continue, please agree to our
            <a href="/legal/terms.html" v-on:click.prevent="show('/legal/terms.html')">Terms of Service</a> and
            <a href="/legal/privacy.html" v-on:click.prevent="show('/legal/privacy.html')">Privacy</a> &amp;
            <a href="/legal/cookie.html" v-on:click.prevent="show('/legal/cookie.html')">Cookie Policy</a>. We
            use cookies for functional and analytical purposes.
          </p>
        </div>
        <div class="welcome__buttons">
          <button type="button" class="button" tabindex="0" v-on:click="agree">
            I agree
          </button>
        </div>
      </div>
      <Drawer v-on:close="toggleDrawer" :showDrawer="showDrawer" :policy="policy"></Drawer>
    </div>
  </transition>
</template>

<script>
import Drawer from "./Drawer";
export default {
  name: "Welcome",
  props: [],
  data: () => {
    return {
      showDrawer: false,
      policy: ''
    }
  },
  transition: "fade",
  computed: {
    showModal() {
      return this.$store.state.welcome.showModal;
    },
    drawerAfterLeave() {
      return !this.$store.state.welcome.showDrawer;
    },
    classObject() {
      return {
        'drawer--visible': this.$store.state.welcome.transitionStatus && this.$store.state.welcome.transitionStatus === 'enter',
        'mobile': this.$device.isMobileOrTablet
      }
    }

  },
  methods: {
    agree() {
      // trigger termly button here
      this.$store.commit("welcome/hide");
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
      //this.$store.commit("welcome/hideDrawer", false);
    },
    show(policy) {
      this.policy = policy;
      this.showDrawer = true;
      // this.drawerAfterLeave = false;
      this.$store.commit("welcome/showDrawer", true);
    }
  },
  components: {
    Drawer
  }
};
</script>

<style lang="scss" scoped>
.welcome {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#102433, 0.95);
  z-index: 99;
  backdrop-filter: blur(10px);

  &.mobile {
    .welcome__content {
      left: 10vw;
      width: 80vw;
    }
  }

  &.drawer--visible{
    z-index: 100;
  }

  &__content {
    left: 25%;
    width: 20rem;
    position: absolute;
    top: 50%;
    color: white;
    transform: translateY(-50%);
  }

  &__title {
    font-size: 1.3em;
    svg {
      margin-top: 0.1rem;
      margin-bottom: 0.4rem;
      width: 11rem;
    }
  }

  &__perks {
    ul {
      padding: 0;
      margin-top: 0;

      li {
        list-style: none;
        margin: 0.5rem 0;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 0.2rem;
        }
      }
    }
  }

  &__terms {
    max-width: 10rem;
    font-size: 0.5rem;
    line-height: 1.3;
    a {
      color: white;
    }
  }

  &__buttons {
    .button {
      background: rgba(33, 150, 243, 1);
      border: 1px solid rgba(33, 150, 243, 1);
      color: white;
      outline: 0;
      font-weight: 500;
      height: 2.5em;
      padding: 0 1.875em;
      font-size: 0.65rem;
      border-radius: 0.3125em;
      cursor: pointer;
      margin-top: 0.7rem;
      transition: 0.7s all cubic-bezier(0.25, 1, 0.25, 1);

      &:hover {
        background: rgb(28, 123, 199);
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s cubic-bezier(0.25, 1, 0.25, 1);
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slidein-enter-active{
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}
.slidein-leave-active{
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}
.slidein-enter, .slidein-leave-to{
  transform: translate3d(105%, 0, 0);
}
</style>
