<template lang="html">
  <transition name="slidein" v-on:enter="enter" v-on:after-leave="leave">
    <div class="drawer" v-show="showDrawer" :class="{'drawer--half': !$device.isMobileOrTablet, mobile: $device.isMobileOrTablet}">
      <div class="drawer__head">
        <button class="drawer__close" tabindex="0" @click.stop="close">
          <svg class="svg-icon" viewBox="0 0 20 20">
                <path d="M12.522,10.4l-3.559,3.562c-0.172,0.173-0.451,0.176-0.625,0c-0.173-0.173-0.173-0.451,0-0.624l3.248-3.25L8.161,6.662c-0.173-0.173-0.173-0.452,0-0.624c0.172-0.175,0.451-0.175,0.624,0l3.738,3.736C12.695,9.947,12.695,10.228,12.522,10.4 M18.406,10c0,4.644-3.764,8.406-8.406,8.406c-4.644,0-8.406-3.763-8.406-8.406S5.356,1.594,10,1.594C14.643,1.594,18.406,5.356,18.406,10M17.521,10c0-4.148-3.374-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.147,17.521,17.521,14.147,17.521,10"></path>
          </svg>
        </button>
        <div class="drawer__logo"></div>
      </div>
      <div class="drawer__scroll-area">
        <div class="drawer__content" v-if="showDrawer">
          <div class="legal">
            <div class="legalnav">
              <ul class="legalnav__list">
                <li class="legalnav__item" v-bind:class="{'legalnav__item--active': policy === '/legal/terms.html'}">
                  <div class="legalnav__arrow">
                    <svg viewBox="0 0 20 20">
							        <path fill="none" d="M8.388,10.049l4.76-4.873c0.303-0.31,0.297-0.804-0.012-1.105c-0.309-0.304-0.803-0.293-1.105,0.012L6.726,9.516c-0.303,0.31-0.296,0.805,0.012,1.105l5.433,5.307c0.152,0.148,0.35,0.223,0.547,0.223c0.203,0,0.406-0.08,0.559-0.236c0.303-0.309,0.295-0.803-0.012-1.104L8.388,10.049z"></path>
						        </svg>
                  </div>
                  <a href="" v-on:click.prevent="show('/legal/terms.html')">Terms of Use</a></li>
                <li class="legalnav__item" v-bind:class="{'legalnav__item--active': policy === '/legal/privacy.html'}">
                  <div class="legalnav__arrow">
                    <svg viewBox="0 0 20 20">
							        <path fill="none" d="M8.388,10.049l4.76-4.873c0.303-0.31,0.297-0.804-0.012-1.105c-0.309-0.304-0.803-0.293-1.105,0.012L6.726,9.516c-0.303,0.31-0.296,0.805,0.012,1.105l5.433,5.307c0.152,0.148,0.35,0.223,0.547,0.223c0.203,0,0.406-0.08,0.559-0.236c0.303-0.309,0.295-0.803-0.012-1.104L8.388,10.049z"></path>
						        </svg>
                  </div>
                  <a href="" v-on:click.prevent="show('/legal/privacy.html')">Privacy Notice</a>
                </li>
                <li class="legalnav__item" v-bind:class="{'legalnav__item--active': policy === '/legal/cookie.html'}">
                  <div class="legalnav__arrow">
                    <svg viewBox="0 0 20 20">
							        <path fill="none" d="M8.388,10.049l4.76-4.873c0.303-0.31,0.297-0.804-0.012-1.105c-0.309-0.304-0.803-0.293-1.105,0.012L6.726,9.516c-0.303,0.31-0.296,0.805,0.012,1.105l5.433,5.307c0.152,0.148,0.35,0.223,0.547,0.223c0.203,0,0.406-0.08,0.559-0.236c0.303-0.309,0.295-0.803-0.012-1.104L8.388,10.049z"></path>
						        </svg>
                  </div>
                  <a href="" v-on:click.prevent="show('/legal/cookie.html')">Cookie Policy</a>
                </li>
              </ul>
            </div>
            <div class="legal__content">
              <iframe
              v-bind:src="policy"
              frameborder="0"
              allowtransparency="true"
              marginheight="0"
              marginwidth="0"
              width="0"
              hspace="0"
              vspace="0"
              height="0"
              scrolling="yes"
              class="legal__frame"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Drawer",
  props: {
    showDrawer: {
      type: Boolean,
      default: false
    },
    policy: String,
  },
  transition: "fade",
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
    leave() {
      this.$store.commit("welcome/setTransition", "after-leave");
    },
    enter() {
      console.log("Drawer is enter");
      this.$store.commit("welcome/setTransition", "enter");
    },
    show(policy) {
      this.policy = policy;
    }
  }
};
</script>

<style lang="scss" scoped>
.drawer {
  background-color: white;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 1000;
  width: 100%;

  &--half {
    width: 50vw;
  }

  &.mobile {
    .drawer__content {
      padding: 0;
    }
    .legalnav {
      width: 30%;
      min-width: 30%;
      max-width: 30%;
    }
    .legal__content {
      width: 70%;
    }
  }

  &--visible {
  /* transform: translate3d(0, 0, 0);;
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);   */
  }

  &--hidden {
    /* transform: translate3d(105%, 0, 0) !important; */
  }

  &__head {
    border-bottom: 1px solid #e8ebed;
    height: 2.7em;
  }

  &__close {
    height: 1.5rem;
    position: absolute;
    left: 1em;
    cursor: pointer;
    background: none;
    border: none;
    top: 1.5em;

    svg{
      path {
        fill: rgb(170, 170, 170);
      }
      width: 28px;
      height: 28px;
    }

    &:hover svg path {
      fill: rgb(90, 90, 90);
    }
    &:focus {
      outline: none;
    }
  }

  &__logo{
    position: absolute;
    top: 0.7rem;
    right: 20px;
    background: url(../assets/mdtn-studio.svg) 0 0 no-repeat;
    background-size: contain;
    width: 4.5rem;
    z-index: 100;
    cursor: pointer;
    &:after {
      content: "";
      padding-bottom: 31.25%;
      display: block;
  }
  }

  &__scroll-area {
    height: calc(100% - 1.6em);
    overflow: overlay;
    position: relative;
  }

  &__content {
    padding-left: 3em;
    padding-right: 3em;
    height: 100%;
    /* padding-top: 2.6em; */
    position: relative;
  }

  .legal{
    height: 100%;

    .legalnav{
      margin-top: 2.6rem;
      float: right;
      max-width: 20%;
      min-width: 20%;

      &__list{
        list-style: none;
        margin: 0;
        padding: 0;
      }

      &__item{
        position: relative;
      }

      &__item:not(:last-child){
        box-shadow: inset 0 -1px 0 0 #e8ebed
      }

      &__item--active{
        font-weight: 600;
        .legalnav__arrow{
          display: block;
        }
      }

      &__arrow{
        width: 0.8em;
        height: 0.8em;
        position: absolute;
        left: -20px;
        top: calc(50% - (0.8em / 2));
        display: none;

        path{
          fill:rgb(90, 90, 90);
        }
      }

      &__item--active &__arrow{
        display: block;
      }

      a{
        text-decoration: none;
        padding: 0.75em 0;
        color: #17181a;
        font-size: 0.7rem;
        display: block;
      }
    }

    &__content{
      height: 100%;
      width: 80%;
    }

    &__frame {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
