// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/mdtn-studio.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body,html{padding:0;margin:0;font-size:24px;width:100vw;-webkit-tap-highlight-color:rgba(0,0,0,0);background:#f0f4f9}body{overflow:overlay}::-moz-selection{color:#fff;background:rgba(33,150,243,.9)}::selection{color:#fff;background:rgba(33,150,243,.9)}*,:after,:before{box-sizing:border-box}.overlay{position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(16,36,51,.95);z-index:30;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px)}.logo{position:absolute;top:1.5rem;right:1.5rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0 no-repeat;background-size:contain;width:6rem;z-index:100;cursor:pointer}.logo:after{content:\"\";padding-bottom:32%;display:block}@media (max-width:2000px){.logo{width:5.5rem}}@media (max-width:1600px){.logo{width:5rem}}@media (max-width:1200px){.logo{width:4rem}}#__nuxt{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;color:#162d3f;min-height:100vh;width:100vw;overflow:hidden;font-size:1rem}#__nuxt,input{font-family:\"Ciutadella W04 Reg\",\"Ciutadella\",sans-serif;font-weight:400}input{font-size:.8rem}h1{margin:0 0 1rem;padding:0;font-weight:500;font-family:\"Ciutadella W04 Medium\",\"Ciutadella\",sans-serif}", ""]);
// Exports
module.exports = exports;
