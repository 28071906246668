<template lang="html">
  <div class="navigation" :class="{show: show}">
    <div class="navigation-group">
      <router-link :to="{ name: 'index'}" tag="a" class="navigation-item standard">Choose template</router-link>
      <router-link :to="{ name: 'faq-faq' }" tag="a" class="navigation-item standard" :class="{'forced-active': $route.name == 'faq-faq'}" v-if="$store.state.prismicAppData.enable_faq_page">FAQ</router-link>
      <router-link :to="{ name: 'specs-spec', params: {spec: 'all'}}" tag="a" class="navigation-item standard" :class="{'forced-active': $route.name == 'specs-spec'}" v-if="$store.state.prismicAppData.enable_specs_page">Specifications</router-link>
    </div>
    <div class="navigation-footer">
      <div class="navigation-group align-above">
        <a class="navigation-item" href="https://madington.com/studio/" target="_blank">
          Learn more about Studio <i class="fal fa-chevron-right"></i>
        </a>
      </div>
      <div class="navigation-group">
        <div class="navigation-item" @click="$emit('showTermlyDrawer', '/legal/terms.html')">
          Terms of Use
        </div>
        <div class="navigation-item" @click="$emit('showTermlyDrawer', '/legal/privacy.html')">
          Privacy Notice
        </div>
        <div class="navigation-item" @click="$emit('showTermlyDrawer', '/legal/cookie.html')">
          Cookie Policy
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'Menu',
  props: ['show']
}
</script>

<style lang="scss" scoped>
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 241px;
    background: #102433;
    color: #dce6f1;
    transition: 0.7s all cubic-bezier(0.25, 1, 0.25, 1);
    z-index: 2;
    left: 0;
    transform: translate(-100%);

    &.show {
      transform: translate(0);
    }

    a {
      text-decoration: none;
    }

    &-group {
      padding: 50px 16px 0;
      .group-title {
        font-size: 0.6em;
        padding: 0 0 0.3em 0;
        color: #c9e4fb;
      }
      &:not(.align-above) + & {
        margin-top: 1em;
      }
      &.align-above {
        position: absolute;
        bottom: 100%;
        padding: 0 12px 1em 4px;
        .navigation-item {
          i {
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translate(125%, -50%);
            font-size: 80%;
            color: #2196f3;
            transition: 0.7s all cubic-bezier(0.25,1,0.25,1)
          }
          &:hover {
            i {
              transform: translate(175%, -50%);
            }
          }
        }
      }
    }
    &-item {
      padding: 0 0 0 8px;
      font-size: 15px;
      line-height: 2.3;
      color: #edf2f6;
      cursor: pointer;
      position: relative;
      display: block;
      font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
      font-weight: 500;
      &.standard:not(.disabled){
        &:before {
          height: 100%;
          position: absolute;
          left: -20px;
          top: 0;
          transform: translate(0, 0);
          content: "";
          background: #2196f3;
          width: 4px;
          transition: 0.4s all cubic-bezier(0.25,1,0.25,1);
        }
      }
      &:not(.disabled):hover {
        color: #fff;
        &:before {
          transform: translate(4px, 0);
        }
      }
      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
      &.standard.nuxt-link-exact-active {
        &:before {
          transform: translate(4px, 0);
        }
      }
      &.forced-active {
        &:before {
          transform: translate(4px, 0) !important;
        }
      }
    }
    &-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 0.9rem;
      padding: 0rem 0.5rem 1.5rem;
      border-top: 1px solid rgba(#edf2f6, 0.1);
      .navigation-group:not(.align-above) {
        padding: 1.5em 12px 0 4px;
      }
    }
  }
</style>
