export const state = () => ({
  showModal: false,
  showDrawer: false,
  transitionStatus: ''
});

export const mutations = {
  set(state, newstate) {
    state.showModal = newstate;
  },
  show(state) {
    state.showModal = true;
  },
  showDrawer(state) {
    state.showDrawer = true;
  },
  hideDrawer(state) {
    state.showDrawer = false;
  },
  hide(state) {
    // fake click the accept button
    try {
      const acceptBtn = document.querySelector("[data-tid=banner-accept]");
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      });
      const ok = acceptBtn.dispatchEvent(event);
      if (ok) {
        state.showModal = false;
        state.showDrawer = false;
      }
    } catch (error) {
      // handle fail, show original termly banner?
      state.showModal = false;
      state.showDrawer = false;
    }

  },
  setTransition(state, newstate) {
    state.transitionStatus = newstate;
  }
};
