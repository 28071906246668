import _ from 'lodash'
import Vue from 'vue'

export const state = () => ({
  // statistics: {
    // days: [],
    // rollup: {
    //   impressions: '-',
    //   exits: '-'
    // }
  // }
  creativeData: {},
  stats: {},
  videoStreams: {}
})

export const mutations = {
  setStats(state, payload){
    // Vue.set(state, 'statistics', {days: _.orderBy(stats.days, ['_id.day'], ['asc']), rollup: stats.rollup})
    Vue.set(state, 'creativeData', payload.creativeData)
    Vue.set(state, 'stats', payload.stats)
    Vue.set(state, 'videoStreams', payload.videoStreams)
    Vue.set(state, 'error', payload.error)
  }
}
