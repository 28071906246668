<template lang="html">
  <div class="steps-sidebar" :class="{expanded: settings.expanded, hide: settings.hide, 'with-nav': navigation}" v-if="validSteps">
    <div class="top-section" @click="$emit('toggleNav')">
      <div class="toggle-nav">
        <i class="fa fa-bars fa-swap-opacity burger-icon" :class="{'show': !navigation}"></i>
        <i class="fa fa-times close-icon" :class="{'show': navigation}"></i>
      </div>
      <div class="toggle-info">
        <span class="navigation-text open-text" :class="{'show': !navigation}">Show navigation</span>
        <span class="navigation-text close-text" :class="{'show': navigation}">Hide navigation</span>
      </div>
    </div>
    <div class="step" v-for="(step, key) in steps" :class="{active: currentStep == key, valid: validSteps[key], last: key == steps.length -1, first: key == 0, error: stepsWithErrors[key] === true, optional: stepsWithErrors[key] === 'optional'}" @click="navigateTo(key)">
      <div class="step-content" :class="{active: currentStep == key, valid: validSteps[key], error: stepsWithErrors[key] === true, optional: stepsWithErrors[key] === 'optional'}">
        <div class="step-icon">
          <i :class="step.icon"></i>
        </div>
        <span class="step-name">{{step.name}}</span>
      </div>
    </div>
    <div class="expand-contract" @click="toggleSidebar">
      <i class="fal" :class="{'fa-chevron-right': !settings.expanded, 'fa-chevron-left': settings.expanded}"></i>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'Steps-Sidebar',
  props: ['steps', 'currentStep', 'navigation'],
  computed: {
    settings() { return this.$store.state.session.sidebar },
    validators(){
      let obj = {};
      if(this.$store.state.session.template){
        _.each(this.$store.state.session.template.templateSettings, (setting) => {
          obj[setting.key] = new RegExp(setting.validation, 'i');
        });
        return obj;
      } else {
        return false;
      }
    },
    validSteps(){
      if(this.$store.state.session.template && this.validators){
        let arr = [];
        _.each(this.$store.state.session.template.templateSettings, (setting) => {
          if(setting.split){
            if(!setting.optional){
              arr.push((
                (this.validators[setting.key].test(setting.splitValue.portrait) && setting.splitValue.portrait !== undefined) &&
                (this.validators[setting.key].test(setting.splitValue.landscape) && setting.splitValue.landscape !== undefined)
              ));
            } else {
              if(setting.optional && (setting.splitValue.landscape !== undefined || setting.splitValue.portrait !== undefined)){
                arr.push((
                  (this.validators[setting.key].test(setting.splitValue.portrait) && setting.splitValue.portrait !== undefined) &&
                  (this.validators[setting.key].test(setting.splitValue.landscape) && setting.splitValue.landscape !== undefined)
                ));
              } else {
                arr.push(undefined);
              }
            }
          } else {
            if(!setting.optional){
              arr.push(this.validators[setting.key].test(setting.value) && setting.value !== undefined);
            } else {
              if(setting.optional && setting.value){
                arr.push(this.validators[setting.key].test(setting.value) && setting.value !== undefined);
              } else {
                arr.push(undefined);
              }
            }
          }
        })
        return arr;
      } else {
        return []
      }
    },
    visitedSteps(){
      return this.$store.state.session.visitedSteps || [];
    },
    stepsWithErrors(){
      if(!!this.validSteps && !!this.visitedSteps){
        let arr = [];
        _.each(this.validSteps, (step, key) => {
          if(this.visitedSteps.includes(key) && step !== undefined && step == false){
            arr.push(true)
          } else {
            if(step === undefined && this.visitedSteps.includes(key)) {
              arr.push('optional')
            } else {
              arr.push(false)
            }
          }
        });
        return arr;
      }
      return [];
    }
  },
  methods: {
    toggleSidebar(){
      this.$store.commit('session/toggleSidebar')
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 700);
    },
    navigateTo(step){
      this.$store.commit('session/goToStep', step)
    }
  }
}
</script>

<style lang="scss" scoped>
  .steps-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 60px;
    // background: #dce6f1;
    background: #152d40;
    // color: rgb(73, 100, 128);
    color: #dce6f1;
    transition: 0.7s all cubic-bezier(0.25,1,0.25,1);
    z-index: 1;
    &.expanded {
      width: 240px;
    }
    &.hide {
      transform: translate(-100%);
      opacity: 0;
    }
    &.with-nav {
      left: 240px;
    }
  }

  .top-section {
    position: relative;
    border-bottom: 1px solid rgba(#102433, .5);
    cursor: pointer;
    transition: 0.7s all cubic-bezier(0.25,1,0.25,1);
    overflow: hidden;
    .toggle-nav {
      width: 36px;
      height: 36px;
      margin: 12px;
      display: inline-block;
      position: relative;
      vertical-align: top;
      i {
        position: absolute;
        top: 50%;
        font-size: .85em;
        left: 50%;
        transform-origin: top left;
        transform: rotate(180deg) translate(-50%, -50%);
        opacity: 0;
        transition: 0.4s all cubic-bezier(0.25,1,0.25,1);
        &.show {
          opacity: 1;
          transform: rotate(0) translate(-50%, -50%);
          .close-icon {
            transform: rotate(360deg) translate(-50%, -50%);
          }
        }
      }
    }
    .toggle-info {
      position: absolute;
      left: 65px;
      top: 50%;
      transform: translateY(-50%);
      white-space: nowrap;
      height: 0.5em;
      .navigation-text {
        font-size: 0.5em;
        line-height: 1.15em;
        opacity: 0;
        transition: 0.4s all cubic-bezier(0.25,1,0.25,1);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        &.close-text {
          transform: translateY(100%);
        }
        &.open-text {
          transform: translateY(-100%);
        }
        &.open-text.show, &.close-text.show {
          transform: translate(0);
          opacity: 1;
        }
      }
    }
    &:hover {
      background: rgba(#314a5f, .2);
    }
  }

  .step {
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 3px;
      height: 23px;
      background: rgba(73, 100, 128, 0.3);
      left: 30px;
      bottom: 100%;
      transform: translate(-50%, 50%);
      transition: 0.7s all ease;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 3px;
      height: 23px;
      background: rgba(73, 100, 128, 0.3);
      left: 30px;
      top: 100%;
      transform: translate(-50%, -50%);
      transition: 0.7s all ease;
    }

    &.active {
      z-index: 3;
      &:after {
        transform: translate(-50%, -40%);
        background: linear-gradient(to top, rgba(33,150,243,0) 0%, rgba(33,150,243,1) 100%);
      }
      &:before {
        transform: translate(-50%, 40%);
        background: linear-gradient(to bottom, rgba(33,150,243,0) 0%, rgba(33,150,243,1) 100%);
      }
    }

    &.valid {
      z-index: 2;
      &:after {
        background: linear-gradient(to top, rgba(33,150,243,0) 0%, #4caf50 100%);
      }
      &:before {
        background: linear-gradient(to bottom, rgba(33,150,243,0) 0%, #4caf50 100%);
      }
    }

    &.error {
      z-index: 2;
      &:after {
        background: linear-gradient(to top, rgba(33,150,243,0) 0%, #e91e63 100%);
      }
      &:before {
        background: linear-gradient(to bottom, rgba(33,150,243,0) 0%, #e91e63 100%);
      }
    }

    &.optional {
      z-index: 2;
      &:after {
        background: linear-gradient(to top, rgba(33,150,243,0) 0%, #5c9eab 100%);
      }
      &:before {
        background: linear-gradient(to bottom, rgba(33,150,243,0) 0%, #5c9eab 100%);
      }
    }

    &.last {
      &:after {
        display: none !important;
      }
    }
    &.first {
      &:before {
        display: none !important;
      }
    }
  }

  .step-content {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    font-size: 1rem;
    cursor: pointer;
    position: relative;
    z-index: 2;
    &.valid {
      .step-icon {
        filter: drop-shadow(0 0 5px rgba(#4caf50, .5));
        color: #fff;
        &:before {
          transform: scale(1);
          opacity: 1;
          // background-color: #4caf50;
          border-color: #4caf50;
        }
      }
    }
    &.error {
      .step-icon {
        filter: drop-shadow(0 0 5px rgba(#e91e63, .5));
        color: #fff;
        &:before {
          transform: scale(1);
          opacity: 1;
          // background-color: #e91e63;
          border-color: #e91e63;
        }
      }
    }
    &.optional {
      .step-icon {
        filter: drop-shadow(0 0 5px rgba(#5c9eab, .5));
        color: #fff;
        &:before {
          transform: scale(1);
          opacity: 1;
          // background-color: #5c9eab;
          border-color: #5c9eab;
        }
      }
    }
    &.active {
      .step-icon {
        color: #fff;
        filter: drop-shadow(0 0 5px rgba(33, 150, 243, .5));
        &:before {
          transform: scale(1.2);
          opacity: 1;
          // background: rgba(33, 150, 243, 1);
          border-color: rgba(33, 150, 243, 1);
        }
      }
      &.valid {
        .step-icon {
          filter: drop-shadow(0 0 5px rgba(#4caf50, .5));
          &:before {
            border-color: #4caf50;
            // background: rgba(33, 150, 243, 1);
            background: rgba(33, 150, 243, .5);
          }
        }
      }
      &.error {
        .step-icon {
          filter: drop-shadow(0 0 5px rgba(#e91e63, .5));
          &:before {
            border-color: #e91e63;
            // background: rgba(33, 150, 243, 1);
            background: rgba(33, 150, 243, .5);
          }
        }
      }
      &.optional {
        .step-icon {
          filter: drop-shadow(0 0 5px rgba(#5c9eab, .5));
          &:before {
            border-color: #5c9eab;
            // background: rgba(33, 150, 243, 1);
            background: rgba(33, 150, 243, .5);
          }
        }
      }
    }
    &:hover:not(.active){
      .step-icon {
        background: rgba(73, 100, 128, 0.3);
      }
    }

    .step-icon {
      width: 36px;
      height: 36px;
      margin: 12px;
      border-radius: 100%;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      font-size: 0.7em;
      transition: 0.7s all cubic-bezier(0.25,1,0.25,1);

      &:before {
        width: 36px;
        height: 36px;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        // background: rgba(33, 150, 243, 1);
        border-width: 2px;
        border-style: solid;
        border-color: rgba(73, 100, 128, 0.1);
        background: rgba(73, 100, 128, 0.3);
        border-radius: 100%;
        // opacity: 0;
        transform: scale(1);
        transition: 0.7s all cubic-bezier(0.25,1,0.25,1);
      }
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .step-name {
      font-size: 0.5em;
    }
  }

  .expand-contract {
    position: absolute;
    top: 50%;
    left: 100%;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    background: #152d40;
    transform: translate(-50%, -50%);
    font-size: 0.5rem;
    cursor: pointer;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-10%, -50%);
    }
  }

  // .fade-enter,
  // .fade-leave-active {
  //   .steps-sidebar {
  //     transform: translate(-60px, 0px);
  //     &.expanded {
  //       transform: translate(-200px, 0px);
  //     }
  //   }
  // }
  // .fade-leave-active,
  // .fade-enter {
  //   .steps-sidebar {
  //     transform: translate(-60px, 0px);
  //     &.expanded {
  //       transform: translate(-200px, 0px);
  //     }
  //   }
  // }
</style>
