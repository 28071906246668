<template>
  <div>
    <NuxtLink :to="{ name: 'index', params: {step: 0} }" tag="div" class="logo" />
    <client-only>
      <navigation :show="true" @showTermlyDrawer="showDrawer" />
      <welcome></welcome>
      <div class="overlay" v-if="showPolicyDrawer"></div>
      <drawer v-on:close="hidePolicyDrawer" :policy="policyPage" :showDrawer="showPolicyDrawer" />
    </client-only>
    <nuxt />
  </div>
</template>

<script>
  import Navigation from '../components/Navigation'
  import Welcome from '../components/Welcome'
  import Drawer from '../components/Drawer';
  import _ from 'lodash'

  export default {
    middleware: 'template',
    components: {
      'navigation': Navigation,
      'welcome': Welcome,
      'drawer': Drawer
    },
    name: 'Default',
    data: function() {
      return {
        policyPage: '',
        showPolicyDrawer: false
      }
    },
    computed: {
      drawerVisible() {
        return this.$store.state.welcome.showDrawer;
      }
    },
    created: function (){},
    mounted: function() {
      if(!this.$store.state.prismicAppData || !this.$store.state.prismicTemplatesData){
        this.$store.dispatch('loadPrismicData');
      }

      this.$store.commit('session/reset');
    },
    methods: {
      showDrawer(page){
        this.policyPage = page;
        this.showPolicyDrawer = true;
      },
      hidePolicyDrawer(){
        this.policyPage = '';
        this.showPolicyDrawer = false;
      }
    },
  }
</script>

<style lang="scss">
// @font-face {
//     font-family: 'Colfax';
//     font-style: normal;
//     font-weight: 400;
//     src: local('Colfax Regular'), url('../assets/fonts/Colfax-Regular.woff') format('woff');
// }
// @font-face {
//     font-family: 'Colfax Regular Italic';
//     font-style: normal;
//     font-weight: normal;
//     src: local('Colfax Regular Italic'), url('../assets/fonts/Colfax-RegularItalic.woff') format('woff');
// }
// @font-face {
//     font-family: 'Colfax';
//     font-style: normal;
//     font-weight: 300;
//     src: local('Colfax Thin'), url('../assets/fonts/Colfax-Thin.woff') format('woff');
// }
// @font-face {
//     font-family: 'Colfax Thin Italic';
//     font-style: normal;
//     font-weight: normal;
//     src: local('Colfax Thin Italic'), url('../assets/fonts/Colfax-ThinItalic.woff') format('woff');
// }
// @font-face {
//     font-family: 'Colfax Light';
//     font-style: normal;
//     font-weight: normal;
//     src: local('Colfax Light'), url('../assets/fonts/Colfax-Light.woff') format('woff');
// }
// @font-face {
//     font-family: 'Colfax Light Italic';
//     font-style: normal;
//     font-weight: normal;
//     src: local('Colfax Light Italic'), url('../assets/fonts/Colfax-LightItalic.woff') format('woff');
// }
// @font-face {
//     font-family: 'Colfax';
//     font-style: normal;
//     font-weight: 500;
//     src: local('Colfax Medium'), url('../assets/fonts/Colfax-Medium.woff') format('woff');
// }
// @font-face {
//     font-family: 'Colfax Medium Italic';
//     font-style: normal;
//     font-weight: normal;
//     src: local('Colfax Medium Italic'), url('../assets/fonts/Colfax-MediumItalic.woff') format('woff');
// }
// @font-face {
//     font-family: 'Colfax';
//     font-style: normal;
//     font-weight: 700;
//     src: local('Colfax Bold'), url('../assets/fonts/Colfax-Bold.woff') format('woff');
// }
// @font-face {
//     font-family: 'Colfax Bold Italic';
//     font-style: normal;
//     font-weight: normal;
//     src: local('Colfax Bold Italic'), url('../assets/fonts/Colfax-BoldItalic.woff') format('woff');
// }
// @font-face {
//     font-family: 'Colfax Black';
//     font-style: normal;
//     font-weight: normal;
//     src: local('Colfax Black'), url('../assets/fonts/Colfax-Black.woff') format('woff');
// }
// @font-face {
//     font-family: 'Colfax Black Italic';
//     font-style: normal;
//     font-weight: normal;
//     src: local('Colfax Black Italic'), url('../assets/fonts/Colfax-BlackItalic.woff') format('woff');
// }


html {}
html, body {
  padding: 0;
  margin: 0;
  font-size: 24px;
  width: 100vw;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  // background-color: rgb(237, 242, 247)
  // background: #dce6f1;
  background: #f0f4f9;
}

body {
  overflow: overlay;
  // transition: 0.7s background cubic-bezier(0.25,1,0.25,1);
}

::selection {
  color: #fff;
  background: rgba(33, 150, 243, 0.9);
}

*, *:after, *:before {
  box-sizing: border-box;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#102433, 0.95);
  z-index: 30;
  backdrop-filter: blur(10px);
}

.logo {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: url(../assets/mdtn-studio.svg) 0 0 no-repeat;
  background-size: contain;
  width: 6rem;
  z-index: 100;
  cursor: pointer;
  &:after {
    content: "";
    padding-bottom: 32%;
    display: block;
  }

  @media (max-width: 2000px){
    width: 5.5rem;
  }
  @media (max-width: 1600px){
    width: 5rem;
  }
  @media (max-width: 1200px){
    width: 4rem;
  }
}
.page {
  // transition: 0.5s all cubic-bezier(0.25,1,0.25,1);
}
#__nuxt {
  // font-family: Colfax, "Neue Helvetica W02", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: "Ciutadella W04 Reg", "Ciutadella", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: rgb(73, 100, 128);
  color: #162d3f;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-size: 1rem;
}
input {
  // font-family: Colfax, "Neue Helvetica W02", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: "Ciutadella W04 Reg", "Ciutadella", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
}

h1 {
  margin: 0 0 1rem 0;
  padding: 0;
  font-weight: 500;
  font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
}

// .layout-enter,
// .layout-leave-active {
//   opacity: 0;
// }
// .layout-enter-active, .layout-leave-active {
//   transition: all 0.7s cubic-bezier(0.25,1,0.25,1);
// }

</style>
