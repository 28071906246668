/**
 * To learn more about Link Resolving check out the Prismic documentation
 */

export default function (doc) {
  if (doc.isBroken) {
    return '/not-found'
  }

  if (doc.type === 'blog_home') {
    return '/';
  }

  if (doc.type === 'templates_overview') {
    return '/templates-overview';
  }

  if (doc.type === 'templates') {
    return '/templates_overview/' + doc.uid
  }

  return '/not-found'
}