<template lang="html">
  <div class="size-modal">
    <div class="box-preview-container">
      <div class="box-preview" :style="{'width': values.width * 0.2 + 'px', 'height': values.height * 0.2 + 'px'}" :class="{'has-x': values.width > 0, 'has-y': values.height > 0}">
        <div class="size-info width" :class="{'show': values.width > 0}">
          <animated-number :value="values.width" :round="true" :duration="400" :easing="'easeInOutCubic'" />
        </div>
        <div class="size-info height" :class="{'show': values.height > 0}">
          <animated-number :value="values.height" :round="true" :duration="400" :easing="'easeInOutCubic'" />
        </div>
      </div>
    </div>
    <div class="size-title">Input desired format</div>
    <div class="inputs">
      <div class="input-wrapper">
        <input type="number" name="" :placeholder="placeholder.x" v-model="values.width" ref="size-input-x" @keyup.enter="onSubmit" @blur="values.width.length < 1 ? placeholder.x = '320' : '0'" @focus="placeholder.x = ''">
      </div>
      <div class="x">x</div>
      <div class="input-wrapper">
        <input type="number" name="" :placeholder="placeholder.y" v-model="values.height" @keyup.enter="onSubmit" @blur="values.height.length < 1 ? placeholder.y = '250' : '0'" @focus="placeholder.y = ''">
      </div>
    </div>
    <transition name="make-valid">
      <div class="button" v-if="values.height > 0 && values.width > 0" @click="onSubmit">Continue</div>
    </transition>
  </div>
</template>

<script>
export default {
  mounted(){
    this.$nextTick(() => {
      this.$refs['size-input-x'].focus();
    })
  },
  data: function(){
    return {
      values: {
        width: '',
        height: ''
      },
      placeholder: {
        x: '320',
        y: '250'
      }
    }
  },
  methods: {
    onSubmit(){
      let values = {
        width: parseInt(this.values.width),
        height: parseInt(this.values.height)
      }
      if(values.width > 0 && values.height > 0){
        this.$emit('setSize', values)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .size-modal {
    width: 400px;
    height: 300px;
    // background: #edf2f7;
    position: absolute;
    z-index: 50;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .box-preview {
      position: absolute;
      border: 1.1px solid rgba(#c0e1ef, 0.1);
      border-radius: 3px;
      bottom: 100%;
      left: 50%;
      transition: 0.7s all cubic-bezier(0.25,1,0.25,1);
      transform-origin: center;
      transform: translate(-50%, 0%);
      min-height: 25px;
      min-width: 25px;
      &.has-x {
        border-bottom-color: rgba(33, 150, 243, 1);
        border-top-color: rgba(33, 150, 243, 1);
      }
      &.has-y {
        border-left-color: rgba(33, 150, 243, 1);
        border-right-color: rgba(33, 150, 243, 1);
      }
      .size-info {
        position: absolute;
        color: #c0e1ef;
        font-size: 0.4rem;
        transition: 0.7s all cubic-bezier(0.25,1,0.25,1);
        opacity: 0;
        &.width {
          bottom: 100%;
          left: 50%;
          transform: translate(-50%, 0%);
        }
        &.height {
          left: 100%;
          top: 50%;
          transform: rotate(90deg) translate(-50%, -100%);
          transform-origin: top left;
        }
        &.show {
          opacity: 1;
          &.width {
            transform: translate(-50%, -50%);
          }
          &.height {
            transform: rotate(90deg) translate(-50%, -150%);
          }
        }
      }
    }
    .box-preview-container {
      position: relative;
      height: 0;
      margin-bottom: 1rem;
    }

    .size-title {
      text-align: center;
      margin: 0 auto;
      padding: 1rem;
      color: #fff;
    }

    .x {
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 1rem;
      color: #c0e1ef;
      transform: translate(-50%, -50%);
    }

    .inputs {
      position: relative;
      width: 240px;
      margin: 0 auto;
      height: 52px;
    }

    .input-wrapper {
      background: #fff;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035), 0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      transition: 0.7s all cubic-bezier(0.25, 1, 0.25, 1);
      width: 100px;
      margin: 0 auto;
      // padding: 0.5em 1em;
      position: absolute;
      text-align: center;
      display: inline-block;
      top: 0;

      &:first-of-type {
        left: 0;
      }
      &:last-of-type {
        right: 0;
      }

      input {
        border: none;
        width: 100%;
        padding: 0.5em 1em;
        font-size: 1em;
        text-align: center;
        background: transparent;
        &:focus {
          outline: none;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &::placeholder {
          color: rgba(33, 150, 243, 0.6);
        }

        /* Firefox */
        &[type=number] {
          -moz-appearance: textfield;
        }
      }
    }.button {
      background: rgba(33, 150, 243, 1);
      position: absolute;
      left: 50%;
      bottom: 10%;
      transform: translate(-50%, -50%);
      border-radius: 30px;
      line-height: 2.5em;
      padding: 0.1em 1.5em 0;
      font-size: 0.8em;
      text-align: center;
      z-index: 100;
      color: #fff;
      cursor: pointer;
      transition: 0.7s all cubic-bezier(0.25,1,0.25,1);
      box-shadow:
        0 0.1px 2.2px rgba(33, 150, 243, 0.087),
        0 0.3px 5.3px rgba(33, 150, 243, 0.125),
        0 0.6px 10px rgba(33, 150, 243, 0.155),
        0 1.1px 17.9px rgba(33, 150, 243, 0.185),
        0 2.1px 33.4px rgba(33, 150, 243, 0.223),
        0 5px 80px rgba(33, 150, 243, 0.31);
      i {
        vertical-align: middle;
        font-size: 0.8em;
        margin-left: 0.5em;
      }
      &:hover {
        background: rgb(28, 123, 199);
        box-shadow:
          0 0.2px 2.2px rgba(28, 123, 199, 0.087),
          0 0.5px 5.3px rgba(28, 123, 199, 0.125),
          0 0.9px 10px rgba(28, 123, 199, 0.155),
          0 1.6px 17.9px rgba(28, 123, 199, 0.185),
          0 2.9px 33.4px rgba(28, 123, 199, 0.223),
          0 7px 80px rgba(28, 123, 199, 0.31);
      }
    }

    .make-valid-enter-active {
      transition: all .7s cubic-bezier(0.25,1,0.25,1);
    }
    .make-valid-leave-active {
      transition: all .7s cubic-bezier(0.25,1,0.25,1);
      opacity: 0;
    }

    .make-valid-enter {
      transform: translate(-50%, 50%);
      opacity: 0.7;
    }
  }
</style>
