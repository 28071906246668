<template>
  <div class="wizard" :class="{mobile: $device.isMobileOrTablet}">
    <NuxtLink :to="{ name: 'index', params: {step: 0} }" tag="div" class="logo" />
    <client-only>
      <transition name="show-sidebar">
        <steps-sidebar :steps="template.steps" :currentStep="$route.params.step" :navigation="navActive" v-if="!sidebar.hide && template" @toggleNav="handleNavToggle"></steps-sidebar>
      </transition>
      <navigation :show="navActive" @showTermlyDrawer="showDrawer" />
    </client-only>
    <div class="page" v-if="session">
      <client-only>
        <div class="offsetter" :style="{top: offset.top, left: offset.left, bottom: offset.bottom}">
          <nuxt />
          <transition name="slide-up-delayed">
            <div class="template-indicator" v-if="template && $route.params.step !== 'complete' && revealTemplateIndicator">
              <div class="template-name">{{template.title}}</div><div class="template-size" v-if="template.type == 'netboard' && template.tagInfo.width">{{template.tagInfo.width}}x{{template.tagInfo.height}}</div>
            </div>
          </transition>
        </div>
      </client-only>
    </div>
    <client-only>
      <welcome></welcome>
      <transition name="fade">
        <div class="overlay" v-if="showPolicyDrawer || sizeModal.show"></div>
      </transition>
      <drawer v-on:close="hidePolicyDrawer" :policy="policyPage" :showDrawer="showPolicyDrawer" />
      <transition name="slide-up">
        <size-modal v-if="sizeModal.show" @setSize="setSize"/>
      </transition>
    </client-only>
  </div>
</template>

<script>
  import Header from '../components/Header'
  import _ from 'lodash'
  import StepsSidebar from '../components/StepsSidebar'
  import Navigation from '../components/Navigation'
  import Drawer from '../components/Drawer';
  import Welcome from '../components/Welcome';
  import SizeModal from '../components/SizeModal';

  export default {
    head: {
      bodyAttrs: {
        class: 'wizard'
      }
    },
    name: 'Wizard',
    middleware: 'template',
    data: function() {
      return {
        navActive: true,
        policyPage: '',
        showPolicyDrawer: false,
        revealTemplateIndicator: false,
        sizeModal: {
          show: false
        }
      }
    },
    components: {
      'steps-sidebar': StepsSidebar,
      'navigation': Navigation,
      'drawer': Drawer,
      'welcome': Welcome,
      'size-modal': SizeModal
    },
    created: function (){},
    mounted() {
      if(!this.$store.state.prismicAppData || !this.$store.state.prismicTemplatesData){
        this.$store.dispatch('loadPrismicData');
      }
      if(process.browser && this.$device.isMobileOrTablet){
        this.$router.replace('/mobile')
      }
      if(process.browser){
        this.$store.dispatch('user/getStoredData');
        let sessionId = this.generateSessionId();


        this.$store.commit('session/setSession', sessionId);
        this.$store.commit('user/setSession', sessionId);
        this.$store.commit('session/selectTemplate', this.$store.state.templates.templates[this.$route.params.template])
        this.$nextTick(() => {

          if(this.$route.params.step !== 'complete'){
            this.$store.commit('session/hideSidebar', false);
            this.$set(this, 'navActive', false);
            this.$gtm.push({
              'event': 'started_template',
              'template_session': sessionId,
              'template_key': this.template.key,
              'template_version': this.template.version,
              'template_public_CPM': this.template.meta.CPM,
              'template_name': this.template.title,
              'template_steps': this.template.steps.length,
              'template_progress': Math.round(((parseInt(this.$route.params.step)) / this.template.steps.length) * 100),
              'template_current_step': parseInt(this.$route.params.step) + 1,
              'template': [
                {
                  'item_name': this.template.title,
                  'item_id': this.template.key,
                  'price': this.template.meta.CPM,
                  'item_brand': 'Madington',
                  'item_variant': this.template.tagInfo.fullscreen ? 'Fullscreen' : `0x0`,
                  'quantity': '1'
                }
              ]
            });
          }
        })

        setTimeout( () => {
          this.$set(this, 'revealTemplateIndicator', true);
        }, 100)

      }
    },
    computed: {
      session(){
        return this.$store.state.session.session
      },
      template(){
        if(this.$store.state.session.template.type == 'netboard' && this.$store.state.session.template.tagInfo.width == 0){
          if(process.browser){
            this.requestSize();
          }
        }

        return this.$store.state.session.template;//this.$store.state.templates.templates[this.$route.params.template]
      },
      sidebar(){
        return this.$store.state.session.sidebar
      },
      offset() {
        let offsets = {top: 0, left: 0, right: 0, bottom: 0};
        offsets.left += (this.sidebar.hide ? 0 : this.sidebar.expanded ? 240 : 60) + (this.navActive ? 240 : 0);
        return {top: `${offsets.top}px`, right: `${offsets.right}px`, bottom: `${offsets.bottom}px`, left: `${offsets.left}px`};
      },
      drawerVisible() {
        return this.$store.state.welcome.showDrawer;
      }
    },
    methods: {
      requestSize(){
        this.$set(this.sizeModal, 'show', true);
      },
      setSize(payload){
        this.$store.commit('session/updateSize', {width: payload.width, height: payload.height});
        this.$gtm.push({
          'event': 'update_netboard_size',
          'template': [
            {
              'item_name': this.template.title,
              'item_id': this.template.key,
              'price': this.template.meta.CPM,
              'item_brand': 'Madington',
              'item_variant': `${payload.width}x${payload.height}`,
              'quantity': '1'
            }
          ]});
        this.$set(this.sizeModal, 'show', false);
        this.$nextTick(() => {
          document.querySelector('input').focus();
        })
      },
      handleNavToggle(){
        this.navActive = !this.navActive;
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 700);
      },
      generateSessionId(a){
        return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, this.generateSessionId)
      },
      showDrawer(page){
        this.policyPage = page;
        this.showPolicyDrawer = true;
      },
      hidePolicyDrawer(){
        this.policyPage = '';
        this.showPolicyDrawer = false;
      }
    },
    watch: {
      '$route.params.step': {
        handler(to){
          if(to == 'complete'){
            this.$set(this, 'navActive', true);
            this.$store.commit('session/hideSidebar', true)
            this.$gtm.push({'event': 'completed_template', 'template_progress': 100, 'template_current_step': 'complete', 'template': [
              {
                'item_name': this.template.title,
                'item_id': this.template.key,
                'price': this.template.meta.CPM,
                'item_brand': 'Madington',
                'item_variant': this.template.tagInfo.fullscreen ? 'Fullscreen' : `${this.template.tagInfo.width}x${this.template.tagInfo.height}`,
                'quantity': '1'
              }
            ]})
          } else {
            this.$gtm.push({'event': 'progress_change_template', 'template_progress': Math.round(((parseInt(this.$route.params.step)) / this.template.steps.length * 100)), 'template_current_step': parseInt(this.$route.params.step) + 1})
          }
        }
      }
    }
  }
</script>

<style lang="scss">


html {}
html, body {
  padding: 0;
  margin: 0;
  font-size: 24px;
  width: 100vw;
  height: 100vh;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  // background-color: rgb(237, 242, 247)
}

body.wizard {
  background-color: rgb(237, 242, 247);

  .wizard.mobile {
    &:after {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #102433;
      z-index: 10000;
      content: "";
      height: 100vh;
      width: 100vw;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s cubic-bezier(0.25, 1, 0.25, 1);
    opacity: 1;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }


  // .layout-enter,
  // .layout-leave-active {
  //   opacity: 0;
  // }
  // .layout-enter-active, .layout-leave-active {
  //   transition: all 0.7s cubic-bezier(0.25,1,0.25,1);
  // }
  .slide-up-enter-active {
    transition: all .7s cubic-bezier(0.25,1,0.25,1);
  }
  .slide-up-leave-active {
    transition: all .7s cubic-bezier(0.25,1,0.25,1);
    opacity: 0;
  }
  .slide-up-enter, .slide-up-leave {
    transform: translateY(5%);
    opacity: 0;
  }

  .slide-up-delayed-enter-active {
    transition: all .7s 0.7s cubic-bezier(0.25,1,0.25,1);
  }
  .slide-up-delayed-leave-active {
    transition: all .7s cubic-bezier(0.25,1,0.25,1);
    opacity: 0;
  }
  .slide-up-delayed-enter, .slide-up-delayed-leave {
    transform: translateY(5%);
    opacity: 0;
  }

}


::selection {
  background: rgba(33, 150, 243, 0.9);
}

*, *:after, *:before {
  box-sizing: border-box;
}

#__nuxt {
  font-family: "Ciutadella W04 Reg", "Ciutadella", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: rgb(73, 100, 128);
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-size: 1rem;
}
input {
  font-family: "Ciutadella W04 Reg", "Ciutadella", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
}

h1 {
  margin: 0 0 1rem 0;
  padding: 0;
  font-weight: 500;
  font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
}


.offsetter {
  position: absolute;
  right: 0;
  transition: 0.7s all cubic-bezier(0.25,1,0.25,1);
  z-index: 0;
}

.show-sidebar-enter-active, .show-sidebar-leave-active {
  transition: transform 0.5s cubic-bezier(0.25,1,0.25,1);
  transform: translateX(-100%);
}
.show-sidebar-enter, .show-sidebar-leave-to  {
  transform: translateX(-100%);
}

.template-indicator {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  color: #fff;
  background: rgba(#4a647f, 0.7);
  white-space: nowrap;
  padding: 0.7em 1em 0.5em;
  line-height: 1;
  font-size: 0.6rem;
  border-radius: 30px;
  line-height: 1;
  transform: translate(0, 0);
  .template-name {
    display: inline-block;
    vertical-align: middle;
  }
  .template-size {
    display: inline-block;
    color: #4a647f;
    font-size: 0.8em;
    background: #c9e4fb;
    padding: 0.6em 0.8em 0.3em;
    margin-right: -0.7em;
    margin-top: -0.3em;
    margin-left: 0.6em;
    border-radius: 20px;
    vertical-align: middle;
    font-weight: 500;
    font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
  }
}

</style>
