<template>
  <div>
    <client-only>
      <div class="mobile-wrapper" @click="showTooEarlyMessage = false">
        <div class="mobile-content">
          <div class="logo"></div>
          <div class="info-text">Madington Studio is currently only<br>available for desktop use.</div>
          <div class="separator"></div>
          <div class="redirector-text">Want to know more about Studio?<br>Visit <a href="https://madington.com/studio" target="_self">madington.com/studio</a></div>
        </div>
        <img class="madington-logo" src="@/assets/madington-teal.svg" alt="" @click="goToMadington">

      </div>
    </client-only>
  </div>
</template>

<script>
  import _ from 'lodash'

  export default {
    components: {},
    name: 'Mobile',
    data: function() {
      return {}
    },
    mounted: function() {

    },
    methods: {
      goToMadington() {
        window.open('https://madington.com');
      }
    }
  }
</script>

<style lang="scss">

html {}
// html, body {
//   padding: 0;
//   margin: 0;
//   font-size: 24px;
//   width: 100vw;
//   -webkit-tap-highlight-color: rgba(0,0,0,0);
//   background: #f0f4f9;
// }

body {
  // transition: 0.7s background cubic-bezier(0.25,1,0.25,1);
}

::selection {
  background: rgba(33, 150, 243, 0.9);
}

*, *:after, *:before {
  box-sizing: border-box;
}

.mobile-wrapper {
  background-color: #102433;
  min-height: 100vh;
  width: 100%;
  position: relative;
  color: #FFFFFF;

  .mobile-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50% ,-50%);
    text-align: center;
    font-size: 0;
    max-width: 400px;
  }

  .info-text {
    font-size: 16px;
    margin-top: 50px;
    margin-bottom: 50px;
    line-height: 26px;
  }

  .redirector-text {
    font-size: 12px;
    margin-top: 20px;
    line-height: 22px;
  }

  .separator {
    width: 50%;
    height: 1px;
    background: #263947;
    display: inline-block;
  }

  .logo {
    width: 40%;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    display: inline-block;
  }

  .madington-logo {
    position: fixed;
    bottom: 40px;
    width: 90px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  a {
    color: #2196F3;
    text-decoration: none;
    position: relative;
    .message-wrapper {
      position: absolute;
      bottom: 100%;
      left: 50%;
      font-size: 12px;
      background: #2196F3;
      color: #fff;
      transform: translate(-50%, 2px);
      opacity: 0;
      padding: 0.75em;
      border-radius: 3px;
      line-height: 1.3;
      white-space: nowrap;
      transition: 0.7s all cubic-bezier(0.25,1,0.25,1);
      &:after {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #2196F3;
        content: "";
        position: absolute;
        top: calc(100% - 1px);
        left: 50%;
        transform: translate(-50%, 0);
      }
      &.show {
        transform: translate(-50%, -6px);
        opacity: 1;
      }
    }
  }
}

</style>
