/* eslint-disable */

export default ({ app: { store } }) => {
    /*
     ** Only run on client-side and only in production mode
     */
    //if (process.env.NODE_ENV !== 'production') return

    window.addEventListener('onWidgetLoadComplete', event => {
        const termlyBanner = document.querySelector("#termly-code-snippet-support");
        termlyBanner.style.display = "none"; // hide it imiditly

        const observer = new MutationObserver((mutationsList, observer) => {
            // Termly have content. I.e we should display welcome screen
            store.commit('welcome/show');
            // run once
            observer.disconnect();
        });
        observer.observe(termlyBanner, { attributes: false, childList: true, subtree: true });
    });
}