<template>
  <div class="statistics">
    <NuxtLink :to="{ name: 'index', params: { step: 0 } }" tag="div" class="logo" />
    <client-only>
      <navigation :show="$route.name == 'loading-session' ? false : true" @showTermlyDrawer="showDrawer"
        v-if="!$device.isMobileOrTablet" />
      <div class="overlay" v-if="showPolicyDrawer"></div>
      <welcome></welcome>
      <drawer v-on:close="hidePolicyDrawer" :policy="policyPage" :showDrawer="showPolicyDrawer" />
      <nuxt />
    </client-only>
  </div>
</template>

<script>
import _ from 'lodash'
import Welcome from '../components/Welcome'
import Drawer from '../components/Drawer'
import Navigation from '../components/Navigation'

export default {
  head: {
    meta: [
      {
        name: 'robots',
        content: 'noindex'
      }
    ],
    bodyAttrs: {
      class: 'statistics'
    }
  },
  name: 'Statistics',
  middleware: 'template',
  components: {
    'navigation': Navigation,
    'welcome': Welcome,
    'drawer': Drawer
  },
  data: function () {
    return {
      policyPage: '',
      showPolicyDrawer: false
    }
  },
  mounted() {
    if (!this.$store.state.prismicAppData || !this.$store.state.prismicTemplatesData) {
      this.$store.dispatch('loadPrismicData');
    }
  },
  created: function () { },
  computed: {
    drawerVisible() {
      return this.$store.state.welcome.showDrawer;
    }
  },
  methods: {
    showDrawer(page) {
      this.policyPage = page;
      this.showPolicyDrawer = true;
    },
    hidePolicyDrawer() {
      this.policyPage = '';
      this.showPolicyDrawer = false;
    }
  },
}
</script>

<style lang="scss">
// @font-face {
//     font-family: 'Colfax';
//     font-style: normal;
//     font-weight: 400;
//     src: local('Colfax Regular'), url('../assets/fonts/Colfax-Regular.woff') format('woff');
// }
//
// @font-face {
//     font-family: 'Colfax';
//     font-style: normal;
//     font-weight: 300;
//     src: local('Colfax Thin'), url('../assets/fonts/Colfax-Thin.woff') format('woff');
// }
//
// @font-face {
//     font-family: 'Colfax';
//     font-style: normal;
//     font-weight: 500;
//     src: local('Colfax Medium'), url('../assets/fonts/Colfax-Medium.woff') format('woff');
// }
//
// @font-face {
//     font-family: 'Colfax';
//     font-style: normal;
//     font-weight: 700;
//     src: local('Colfax Bold'), url('../assets/fonts/Colfax-Bold.woff') format('woff');
// }


html {}
html,
body {
  padding: 0;
  margin: 0;
  font-size: 24px;
  width: 100vw;
  height: 100vh;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  // background-color: rgb(237, 242, 247)
}

body.statistics {
  background-color: rgb(237, 242, 247)
}

::selection {
  background: rgba(33, 150, 243, 0.9);
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

#__nuxt {
  // font-family: Colfax, "Neue Helvetica W02", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: "Ciutadella W04 Reg", "Ciutadella", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: rgb(73, 100, 128);
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-size: 1rem;
}

input {
  font-family: "Ciutadella W04 Reg", "Ciutadella", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
}

h1 {
  margin: 0 0 1rem 0;
  padding: 0;
  font-weight: 500;
  font-family: "Ciutadella W04 Medium", "Ciutadella", sans-serif;
}


// .layout-enter,
// .layout-leave-active {
//   opacity: 0;
// }
// .layout-enter-active, .layout-leave-active {
//   transition: all 0.7s cubic-bezier(0.25,1,0.25,1);
// }
//
// .offsetter {
//   position: absolute;
//   transition: 0.7s inset cubic-bezier(0.25,1,0.25,1);
//   z-index: 0;
// }
</style>
