<template>
  <div class="preview">
    <client-only>
      <nuxt />
    </client-only>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  middleware: 'template',
  head: {
    meta: [
      {
        name: 'robots',
        content: 'noindex'
      }
    ],
    bodyAttrs: {
      class: 'preview'
    }
  },
  name: 'Preview',
  components: {},
  data: function () {
    return {
    }
  },
  created: function () {

  },
  methods: {},
}
</script>

<style lang="scss">
.preview {}
</style>
