<template lang="html">
  <div class="header">
  </div>
</template>

<script>

export default {
  name: 'Header',
  props: []
}
</script>

<style lang="scss" scoped></style>
