import Vue from 'vue';
import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: 'AKIA4TBAVGIZXW2QP6MB',
  secretAccessKey: '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',
  region: 'eu-north-1'
});

Vue.prototype.AWS = AWS;
