import Vue from 'vue'

export const state = () => ({
  templates: {}
})

export const mutations = {
  setTemplates(state, payload){
    Vue.set(state, 'templates', payload);
  }
}

export const actions = {

}
