import _ from 'lodash'
import Vue from 'vue'


export const state = () => ({
  session: '',
  emails: [],
  advertisers: [],
  URLs: [],
  creatives: []
});

export const mutations = {
  setSession(state, session) {
    Vue.set(state, 'session', session);
  },
  pushEmail(state, email){
    let emails = Array.isArray(email) ? email : _.uniqBy(_.unionBy([email], state.emails, 'value'), 'session');
    localStorage.setItem('emails', JSON.stringify(emails))
    Vue.set(state, 'emails', emails);
  },
  pushAdvertiser(state, advertiser){
    let advertisers = Array.isArray(advertiser) ? advertiser : _.uniqBy(_.unionBy([advertiser], state.advertisers,  'value'), 'session');
    localStorage.setItem('advertisers', JSON.stringify(advertisers))
    Vue.set(state, 'advertisers', advertisers);
  },
  pushURLs(state, URL){
    let URLs;
    if(Array.isArray(URL)){
      URLs = URL;
    } else {
      URLs = _.uniqBy(_.unionBy([URL], state.URLs,  (url) => {
        return url.split ? JSON.stringify(url.value) : url.value
      }), 'session')
    }
    localStorage.setItem('URLs', JSON.stringify(URLs))
    Vue.set(state, 'URLs', URLs);
  },
  pushCreatives(state, creative){
    let creatives = Array.isArray(creative) ? creative : _.union(state.creatives, [creative]);
    localStorage.setItem('creatives', JSON.stringify(creatives))
    Vue.set(state, 'creatives', creatives);
  },
  removeValueFrom(state, payload){
    let currentState = _.clone(state[payload.key]);
    currentState.splice(payload.index, 1);
    localStorage.setItem(payload.key, JSON.stringify(currentState))
    Vue.set(state, payload.key, currentState)
  }
};

export const actions = {
  getStoredData({ commit }) {
    if(localStorage.getItem('emails')){
      commit('pushEmail', JSON.parse(localStorage.getItem('emails')), true)
    }
    if(localStorage.getItem('advertisers')){
      commit('pushAdvertiser', JSON.parse(localStorage.getItem('advertisers')), true)
    }
    if(localStorage.getItem('URLs')){
      commit('pushURLs', JSON.parse(localStorage.getItem('URLs')), true)
    }
    if(localStorage.getItem('creatives')){
      commit('pushCreatives', JSON.parse(localStorage.getItem('creatives')), true)
    }
  }
}
